import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'
import { App } from './app/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  singleSpaCssLifecycles,
  cssScope: 'data-pacman-workflow-details-spa',
  rootComponent: App
  // sentry: {
  //   publicKey: '',
  //   projectId: '',
  //   releaseVersion: process.env.SPA_NAMED_VERSION || process.env.PKG_VERSION
  // }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'pacman-workflow-details-spa'
