import React from 'react'
import { useQuery } from 'react-query'
import { fetchLogData } from '../queries'
import { CardContainer } from '@toasttab/buffet-pui-card'

import cx from 'classnames'
const Convert = require('ansi-to-html')
const ansi = new Convert({
  escapeXML: true,
  stream: false, // streaming can choke and end up with terrible performance issues
  bg: '#fff',
  fg: 'rgb(37, 37, 37)'
})

const LogDisplay = ({ taskId }) => {
  const {
    data: logs,
    error,
    isLoading
  } = useQuery(['fetchLogData', { taskId }], fetchLogData, {})

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (error) {
    return <div>Error: {error.message}</div>
  }

  return <LogLines lines={logs}></LogLines>
}

const LogLines = ({ lines }) => {
  return (
    <>
      <pre
        tabIndex='0'
        className={cx(
          'overflow-auto outline-none bg-white md:rounded shadow-md-flush md:shadow-md',
          {
            'whitespace-normal': true
          }
        )}
        style={{
          height: '50vh',
          maxHeight: '480px'
        }}
      >
        {lines.length === 0 ? (
          <></>
        ) : (
          <div className='p-4'>
            {lines.map((line) => (
              <div
                // We are trusting ansi-to-html's escapeXML flag to work securely
                dangerouslySetInnerHTML={{ __html: ansi.toHtml(line) }}
              />
            ))}
          </div>
        )}
      </pre>
    </>
  )
}

const LogsContainer = ({ taskId }) => {
  return (
    <>
      {
        <CardContainer
          className='w-auto mb-4 relative'
          style={{
            minHeight: '48px'
          }}
          noPadding
        >
          <LogDisplay taskId={taskId} />
        </CardContainer>
      }
    </>
  )
}

export default LogDisplay
