import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { useParams, BrowserRouter, Routes, Route } from 'react-router-dom'

import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels
} from '@toasttab/buffet-pui-tabs'

import { WorkflowStatus } from '../../packages/DetailsPage/components/WorkflowStatus'
import { fetchWorkflowDetails } from '../../packages/DetailsPage/queries/queries'
import LogsContainer from '../../packages/DetailsPage/components/LogsPanel'

const SubLabel = ({ children }) => (
  <label className='font-semibold'>{children}</label>
)

const SubField = ({ children }) => (
  <div className='flex flex-row type-default'>{children}</div>
)

const AppContent = () => {
  const [isLogsPanelHidden, setIsLogsPanelHidden] = useState(false)
  const params = useParams()
  console.log(params)
  const taskId = params.taskId

  const {
    data: workflow,
    error,
    isLoading
  } = useQuery(
    [
      'fetchWorkflowDetails',
      {
        taskId
      }
    ],
    fetchWorkflowDetails
  )

  if (error) {
    return (
      <div>
        <p className='mx-4 md:mx-0'>
          An error happened: {JSON.stringify(error)}
        </p>
        <p className='mx-4 md:mx-0'>Likely issue: taskId does not exist</p>
      </div>
    )
  }

  return !isLoading ? (
    <>
      <div className='my-2 type-headline-3'>{workflow.name}</div>
      <div className='flex flex-row gap-2 justify-stretch'>
        <div className='flex-grow my-2 space-y-2'>
          <SubField>
            <SubLabel>Task-id:</SubLabel>
            <div>{workflow.taskId}</div>
          </SubField>
          <SubField>
            <SubLabel>Status:</SubLabel>
            <WorkflowStatus status={workflow.status.toUpperCase()} />
          </SubField>
        </div>
      </div>
      <Tabs className='w-full'>
        <TabList className='w-full px-4 md:px-0'>
          <Tab>Logs</Tab>
        </TabList>
        <TabPanels className='py-4 md:py-6'>
          <TabPanel
            onSelection={() => setIsLogsPanelHidden(false)}
            onBlur={() => setIsLogsPanelHidden(true)}
          >
            <LogsContainer taskId={taskId}></LogsContainer>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  ) : (
    <>isLoading</>
  )
}

export const App = () => (
  <BrowserRouter>
    <Routes>
      <Route
        path=':repoName/workflow-details/:taskId'
        element={<AppContent />}
      />
      <Route
        path='*'
        element={<div>Please access workflow details via github PR</div>}
      />
    </Routes>
  </BrowserRouter>
)
