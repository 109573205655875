import { securedFetch } from '@local/jwt-manager'

const toJson = (response, followRedirect = true) => {
  return checkResponse(response, followRedirect).then((r) => r.json())
}

const toText = (response) => {
  return checkResponse(response).then((r) => r.text())
}

export const downloadBinary = (url) =>
  securedFetch(url)
    .then(checkResponse)
    .then(
      (response) =>
        new Promise((resolve, reject) => {
          response
            .blob()
            .then((blob) => resolve(blob, response.headers.get('content-type')))
            .catch(reject)
        })
    )

export const downloadLogs = null //TODO

export const fetchLogData = async (_, params) => {
  const utf8Decoder = new TextDecoder('utf-8')
  let headers = { 'Content-Type': 'application/json' }
  const response = await securedFetch(
    `/toasttab.grpc.pacman.v1.PacmanService/GetTaskLogs`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId: params.taskId }),
      headers: headers
    }
  )
  const newline = /\r?\n/gm
  let startIndex = 0
  let result
  const reader = response.body.getReader()
  let { value: chunk, done: readerDone } = await reader.read()
  chunk = chunk ? utf8Decoder.decode(chunk) : ''

  let combinedMessage = []
  while (true) {
    const result = newline.exec(chunk)
    if (!result) {
      if (readerDone) break
      const remainder = chunk.substr(startIndex)
      ;({ value: chunk, done: readerDone } = await reader.read())
      chunk = remainder + (chunk ? utf8Decoder.decode(chunk) : '')
      startIndex = newline.lastIndex = 0
      continue
    }

    const message = JSON.parse(chunk.substring(startIndex, result.index)).result
      .message
    combinedMessage.push(...message.split('\n'))
    startIndex = newline.lastIndex
  }

  if (startIndex < chunk.length) {
    const message = JSON.parse(chunk.substring(startIndex, result.index)).result
      .message
    combinedMessage.push(...message.split('\n'))
  }
  console.log(combinedMessage)
  return combinedMessage
}

export const fetchFinishedLogs = null //TODO

export const checkResponse = (response, followRedirect = true) => {
  if (response.redirected && !response.ok && followRedirect) {
    return fetch(response.url).then(checkResponse)
  }

  if (!response.ok) {
    response.message = `Error while executing request: ${response.url}. Status: ${response.status}`
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

export const fetchWorkflowDetails = (_, params) => {
  let headers = { 'Content-Type': 'application/json' }
  return securedFetch(
    `/toasttab.grpc.pacman.v1.PacmanService/GetTaskMetadata`,
    {
      method: 'POST',
      body: JSON.stringify({ taskId: params.taskId }),
      headers: headers
    }
  ).then(toJson)
}
